import React from "react";
import { Link } from "react-router-dom";

import LogoLight from "../assets/images/logo-light.png";

export default function Footer() {

    return (
        <>
            {/*  Start Footer  */}
            <footer className="py-8 bg-slate-800 dark:bg-gray-900">
                <div className="container">
                    <div className="grid md:grid-cols-12 items-center">
                       

                        <div className="md:col-span-12 md:mt-0 mt-8">
                            <div className="text-center">
                                <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Design & Develop by Citizens for Durell Reeves </p>
                            </div>
                        </div>

               
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer --> */}

            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-orange-600 text-white leading-9"><i className="uil uil-arrow-up"></i></Link>
            {/* <!-- Back to top --> */}
        </>
    );

}

