import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";

import AboutImage from "../assets/images/family.jpg";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
                {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  <Link2 to="#" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-red-600">
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                  </Link2>
                </div> */}
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-red-600 text-base font-medium uppercase mb-2">Who is Durell Reeves ?</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">About Me</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Durell Reeves is a St. louis native and a primary resident of suburban North County.
Dedicated community leader, entreprenuer and advocate for positive change, running
as a State Representative candidate with a strong vision for economic growth, job
creation, and family-centric infrastructure development. Born and raised in the inner
west city of St. Louis, Durell has deep roots in the community and a profound
understanding of its needs and aspirations.</p>

                <div className="relative mt-10">
                  {/* <a href="#portfolio" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md">View Portfolio</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      />
    </>
  );

}

